<template>
  <el-tabs v-model="curoption.activeTabName" @tab-click="handleClick" v-if="curoption && curoption.data.length > 1" style="height: 100%;" :key="funnelkey">
    <el-tab-pane
      :label="tabletab.tabname"
      :name="'tab' + tabletabidx"
      v-for="(tabletab, tabletabidx) in showtabs"
      :key="tabletabidx + 1"
      style="height: calc(100% - 10px);"
      class="my-table"
    >
      <el-table
        :data="currentChart.version == '2.0' ? tabletab.showdata : tabletab.showdata"
        @sort-change="params => sortChange(params, tabletabidx)"
        :max-height="maxHeight"
        size="small"
        border
      >
        <el-table-column
          label="序号"
          type="index"
          :min-width="colwidth('序号')"
          :show-overflow-tooltip="true"
          :resizable="false"
          v-if="opts.styleSet && opts.styleSet.enableRownumber"
        ></el-table-column>
        <el-table-column
          v-for="(t, index) in tabletab.tableHead"
          :min-width="colwidth(t.title)"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="t.alias"
          :label="t.title"
          :render-header="labelHead"
          sortable="custom"
        >
          <template slot-scope="scope">
            <template v-if="currentChart.version == '2.0'">
              <div v-if="t.usage == 'Measure'">
                <a herf="javascript:void(0)" @click="onFunnel2(scope.row, t, tabletab.tableHead)" class="link" target="_blank">{{ scope.row[t.alias] }}</a>
              </div>
              <div v-else :style="{ color: scope.row[t.alias] > 0 ? '#45BB44' : scope.row[t.alias] < 0 ? '#FF6054' : '' }">
                {{ scope.row[t.alias] }}
              </div>
            </template>
            <template v-else>
              <div v-if="t.usage == 'measure'">
                <a herf="javascript:void(0)" @click="onFunnel(scope.row, t.alias, tabletabidx)" class="link" target="_blank">{{ scope.row[t.alias] }}</a>
              </div>
              <div v-else-if="t.usage == 'rate'" :style="{ color: scope.row[t.alias] > 0 ? '#45BB44' : scope.row[t.alias] < 0 ? '#FF6054' : '' }">
                {{ (scope.row[t.alias] * 100).toFixed(2) }}%
              </div>
              <div v-else>
                {{ scope.row[t.alias] }}
              </div>
            </template>
          </template>
        </el-table-column>
        <span
          class="loadbtn"
          v-if="!(opts.styleSet && opts.styleSet.enablePaging) && tabletab.opt.length > 40 && tabletab.showdata.length < tabletab.opt.length"
          slot="append"
          @click="appendTable"
          style="display:block;text-align:center;"
          >加载更多</span
        >
        <span
          class="loadbtn"
          v-if="!(opts.styleSet && opts.styleSet.enablePaging) && tabletab.opt.length > 40 && tabletab.showdata.length == tabletab.opt.length"
          slot="append"
          style="display:block;text-align:center;"
          >已经到底部了</span
        >
      </el-table>
      <div class="tabListPage" v-if="opts.styleSet && opts.styleSet.enablePaging">
        <el-pagination
          @current-change="val => handleCurrentChange(val, tabletabidx)"
          :current-page="tabletab.currentPage"
          :page-sizes="pageSizes"
          :page-size="tabletab.PageSize"
          layout="total, prev, pager, next"
          :total="tabletab.totalCount"
        >
        </el-pagination>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import detailJS from '@/script/pagejs/detailUrl'
import { mapState } from 'vuex'
import BI_config from '@/utils/config'
export default {
  props: ['chartDataItem', 'chartdata', 'rowheight', 'dontclickable'],
  data() {
    return {
      currentChart: null,
      curoption: null,
      maxHeight: 0,
      opts: null,
      funneldata: [],
      funnelkey: 0,
      showtabs: [],
      // 个数选择器
      pageSizes: [10, 20, 30, 40, 50, 100],
    }
  },
  computed: {
    ...mapState({
      projectID: state => state.projectID,
    }),
  },
  created() {
    this.currentChart = this.chartdata
    this.curoption = this.chartdata?.option || null
    this.opts = this.chartdata.settings || this.chartdata.reqObj.settings
    this.funneldata = this.curoption.data.map(x => {
      x.sortData = x.opt
      return x
    })
    this.getMaxHeight()
    this.getShowdata(0, 1, '')
  },
  methods: {
    handleClick(tab) {
      this.curoption.activeTabName = tab.name
      this.funnelkey++
      //更新导出
      if (!this.chartdata.version === '2.0') {
        this.currentChart.excelsObj = this.curoption.excelsFunnelObj[tab.name]
      }
    },
    labelHead(h, { column }) {
      let l = column.label ? column.label.length + 4 : 4
      let f = 16 //每个字大小，其实是每个字的比例值，大概会比字体大小差不多大一点，
      column.minWidth = f * l //字大小乘个数即长度 ,注意不要加px像素，这里minWidth只是一个比例值，不是真正的长度
      //然后将列标题放在一个div块中，注意块的宽度一定要100%，否则表格显示不完全
      return h('span', { class: 'table-head', style: { width: '100%' } }, [column.label])
    },
    colwidth(v, prop) {
      if (typeof prop === 'string' && prop.match(/day_dim|event_time_dim/)) {
        return 170
      } else {
        let $span = document.createElement('span')
        document.body.appendChild($span)
        $span.innerText = v
        let w = $span.offsetWidth + 40
        document.body.removeChild($span)
        return w > 120 ? w : 120
      }
    },

    onFunnel(row, k, tabletabidx) {
      detailJS.behaviorUrl(this, row, k, '', '', this.currentChart, tabletabidx)
    },
    onFunnel2(row, t, head) {
      if (this.dontclickable) return
      let query = JSON.parse(JSON.stringify(this.chartdata.reqObj.parameter))
      const injectedArguments = this.chartdata.reqObj.injectedArguments
      //如果是卡片组合内的 时间应改为卡片组合的时间
      if (this.chartDataItem) {
        query.components.forEach(x => {
          if (x.type === 'TimePicker') {
            x.timeRange = this.chartDataItem.timeRange[0]
          }
        })
      }

      let groups = head
        .filter(x => {
          return x.usage == 'Group'
        })
        .map(x => {
          const alias_current = `${x.alias}_current`
          const alias_raw = `${x.alias}_raw`
          return {
            alias: x.alias,
            value: row[alias_current] ? row[alias_current] : row[alias_raw] ? row[alias_raw] : row[x.alias],
            usage: x.usage,
          }
        })

      let position = {
        type: 'Position',
        positions: [
          {
            alias: t.alias,
            value: row[t.alias],
            usage: t.usage,
          },
          ...groups,
        ],
      }
      query.components.push(position)
      query.name = this.chartdata.reqObj.title
      if (injectedArguments && injectedArguments.prefilter) {
        query.prefilter = injectedArguments.prefilter
      }
      let urlquery = this.$commonJs2.setUrl(query)
      let routes = this.$router.resolve({
        path: `${BI_config.analysisProxy}/template/entityList/${this.projectID}/${t.clickableType}`,
        hash: urlquery,
      })
      window.open(routes.href)
    },
    getMaxHeight() {
      const height = document.documentElement.clientHeight
      this.maxHeight =
        this.opts && this.opts.styleSet && this.opts.styleSet.enablePaging
          ? this.rowheight
            ? parseInt(this.rowheight) - 75
            : height - 180
          : this.rowheight
          ? parseInt(this.rowheight) - 45
          : height - 130
    },

    sortChange(params, tabidx) {
      let { prop, order } = params
      this.funneldata.forEach((item, i) => {
        if (i !== tabidx) return
        item.currentPage = 1
        let copydata = JSON.parse(JSON.stringify(item.opt))
        let sumItem = null
        if (this.opts.aggregation && this.opts.aggregation !== 'none') {
          sumItem = copydata.shift()
        }
        if (order !== null) {
          copydata.sort(function(a, b) {
            let p1 = a[prop]
            let p2 = b[prop]
            /**
             * 百分比对比转换
             * 空数据'-'  转换成最小值
             */
            if (prop.includes('Rate')) {
              p1 = p1 && p1.replace(',', '')
              p2 = p2 && p2.replace(',', '')
              p1 = p1 === '-' ? -1000000 : parseFloat(p1)
              p2 = p2 === '-' ? -1000000 : parseFloat(p2)
            }
            if (['总计', '平均值', '中位数', '去零平均值', '全部'].includes(b['g0'])) {
              return 1
            } else if (order === 'ascending') {
              if (p1 > p2) {
                return 1
              } else {
                return -1
              }
            } else if (order === 'descending') {
              if (p1 < p2) {
                return 1
              } else {
                return -1
              }
            }
          })
        }
        sumItem && copydata.unshift(sumItem)
        this.$set(item, 'sortData', copydata)
        const showdata = copydata.filter((x, idx) => {
          return idx < item.currentPage * item.PageSize
        })
        this.showtabs[tabidx].showdata = showdata
      })
      this.showtabs.forEach((x, i) => {
        this.$set(x, 'showdata', x.showdata)
        this.$set(this.showtabs, i, x)
      })
    },

    handleSizeChange(val, idx) {
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.getShowdata(idx, 1, val)
    },
    // 显示第几页
    handleCurrentChange(val, idx) {
      this.getShowdata(idx, val, '')
    },

    getShowdata(tabidx, currentPage, PageSize) {
      this.showtabs = this.funneldata.map(t => {
        t.currentPage = currentPage ? currentPage : t.currentPage ? t.currentPage : 1
        t.totalCount = (t.opt && t.opt.length) || 0
        t.PageSize = this.opts.styleSet && this.opts.styleSet.enablePaging ? (PageSize ? PageSize : t.PageSize ? t.PageSize : 15) : 40
        t.showdata = t.sortData.filter((x, idx) => {
          return idx >= (t.currentPage - 1) * t.PageSize && idx < t.currentPage * t.PageSize
        })
        return t
      })
    },

    appendTable() {
      this.showtabs = this.funneldata.map(t => {
        t.currentPage++
        t.showdata = t.sortData.filter((x, idx) => {
          return idx < t.currentPage * t.PageSize
        })
        return t
      })
    },
  },
  mounted() {
    let _this = this
    window.onresize = function() {
      _this.getMaxHeight()
    }
  },
}
</script>

<style lang="scss" scoped>
.loadbtn {
  display: block;
  line-height: 36px;
  cursor: pointer;
  font-size: 12px;
  color: #999;
}
.link {
  color: #70b4fa;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
