<template>
  <div class="my-table" :class="{ table_c1: table_c1, 'paging-table': styleSet.enablePaging, noheader: showHeader() }" :key="tabkey">
    <el-table
      ref="tableref"
      :data="showdata"
      :span-method="objectSpanMethod"
      border
      @sort-change="sortChange"
      :key="JSON.stringify(opts)"
      :max-height="maxHeight"
    >
      <el-table-column
        label="序号"
        type="index"
        :min-width="colwidth('序号')"
        :show-overflow-tooltip="true"
        :resizable="false"
        v-if="styleSet.enableRownumber"
      ></el-table-column>
      <my-column
        v-for="(item, index) in col"
        :key="index"
        :col="item"
        :opts="styleSet"
        :analysisType="opts.analysisType"
        :newopt="newopt"
        :dontclickable="dontclickable"
        @redirection="redirection"
      ></my-column>
      <span
        class="loadbtn"
        v-if="!styleSet.enablePaging && orignData.length > 40 && showdata.length < orignData.length"
        slot="append"
        @click="appendTable"
        style="display:block;text-align:center;"
        >加载更多</span
      >
      <span
        class="loadbtn"
        v-if="!styleSet.enablePaging && orignData.length > 40 && showdata.length == orignData.length"
        slot="append"
        style="display:block;text-align:center;"
        >已经到底部了</span
      >
    </el-table>
    <div class="tabListPage" v-if="styleSet.enablePaging">
      <el-pagination
        @size-change="handleSizeChange"
        popper-class="thmemBottomMenu"
        @current-change="handleCurrentChange"
        :pager-count="5"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import MyColumn from './MyColumn'
// import tableUtil from './tableUtil'
export default {
  data() {
    return {
      maxHeight: 0,
      tabkey: 0,
      showdata: [], //显示数据
      orignData: [], //原数据
      sortData: [], //排序后原数据

      newopt: {},
      styleSet: {},

      addMorePage: 1, //加载更多第几页
      // 默认显示第几页
      currentPage: 1,
      // 总条数，
      totalCount: 1,
      // 个数选择器
      pageSizes: [10, 20, 30, 40, 50, 100],
      // 默认每页显示的条数
      PageSize: 20,
    }
  },
  components: {
    MyColumn,
  },
  props: {
    chartDataItem: {
      type: Object,
    },
    opts: {
      type: Object,
    },
    col: {
      type: Array,
    },
    data: {
      type: Array,
    },
    chartoption: {
      type: Object,
    },
    resizedidx: {
      type: Number,
    },
    rowheight: {
      type: Number,
    },
    dontclickable: {
      type: Boolean,
    },
  },
  computed: {
    // 留存分析 去掉padding 撑满背景
    table_c1() {
      return !!(this.opts && this.opts.analysisType && this.opts.analysisType.match(/Retention|Scatter/))
    },
  },
  created() {
    this.init()
    this.getMaxHeight()

    this.newopt['version'] = this.chartoption.version
    this.newopt['columns'] = this.chartoption.orginResult && this.chartoption.orginResult.columns
    this.newopt['groups'] =
      this.chartoption.version == '2.0' && this.chartoption.reqObj.parameter && this.chartoption.reqObj.parameter.groups
        ? this.chartoption.reqObj.parameter.groups
        : []
    this.newopt['chartSetting'] = this.chartoption.reqObj.setting || this.chartoption.reqObj.settings
  },
  watch: {
    resizedidx: function() {
      this.tabkey++
    },
  },
  methods: {
    init() {
      this.styleSet = (this.opts && this.opts.styleSet) || {}
      this.orignData = this.data
      this.sortData = this.data

      this.totalCount = this.orignData.length
      this.PageSize = this.styleSet.enablePaging ? 20 : 40
      this.getShowdata()
    },
    sortChange(params) {
      this.currentPage = 1
      let { prop, order } = params
      let copydata = JSON.parse(JSON.stringify(this.orignData))
      let sumItem = null
      if (this.opts.aggregation && this.opts.aggregation !== 'none') {
        sumItem = copydata.shift()
      }
      if (order !== null) {
        copydata.sort((a, b) => {
          let p1 = a[prop]
          let p2 = b[prop]
          // 开启占比的值拆分一下
          if (String(p1).includes('%)') || String(p1).includes('(-)')) {
            const splitval = p1.split('(')
            p1 = Number(splitval[0])
          }
          if (String(p2).includes('%)') || String(p2).includes('(-)')) {
            const splitval = p2.split('(')
            p2 = Number(splitval[0])
          }

          /**
           * 百分比对比转换
           * 空数据'-'  转换成最小值
           */
          // if (prop.usage === 'Rate') {
          if (prop.includes('Rate') || prop.includes('_occ')) {
            p1 = p1 && p1.replace(',', '')
            p2 = p2 && p2.replace(',', '')
            p1 = p1 === '-' ? -1000000 : parseFloat(p1)
            p2 = p2 === '-' ? -1000000 : parseFloat(p2)
          }

          const hzarr = ['总计', '平均值', '中位数', '去零平均值', '全部']
          let gval = b['g0']
          if (this.opts && this.opts.analysisType && this.opts.analysisType.toLowerCase() == 'advance') {
            let g0arr = []
            for (let item in b) {
              if (item.includes('_g0')) {
                g0arr.push(b[item])
              }
            }
            let intersection = hzarr.filter(val => {
              return g0arr.indexOf(val) > -1
            })
            gval = intersection[0]
          }
          if (hzarr.includes(gval)) {
            return 1
          } else if (order === 'ascending') {
            if (p1 > p2) {
              return 1
            } else {
              return -1
            }
          } else if (order === 'descending') {
            if (p1 < p2) {
              return 1
            } else {
              return -1
            }
          }
        })
      }
      sumItem && copydata.unshift(sumItem)
      this.sortData = copydata
      this.$emit('changesort', this.sortData)

      this.showdata = this.sortData.filter((x, idx) => {
        return idx >= (this.currentPage - 1) * this.PageSize && idx < this.currentPage * this.PageSize
      })
    },
    /**
     * td单元格渲染
     * row 行
     * columnIndex 列
     */
    objectSpanMethod({ row, columnIndex }) {
      if (row.table_span && row.table_span[columnIndex]) {
        return {
          rowspan: row.table_span[columnIndex].rowspan,
          colspan: row.table_span[columnIndex].colspan,
        }
      }
    },
    handleSizeChange(val) {
      // 改变每页显示的条数
      this.PageSize = val
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage = 1
      this.getShowdata()
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val
      this.getShowdata()
    },

    colwidth(v) {
      let $span = document.createElement('span')
      document.body.appendChild($span)
      $span.innerText = v
      let w = $span.offsetWidth + 40
      document.body.removeChild($span)
      return w > 120 ? w : 120
    },

    getShowdata() {
      this.showdata = this.sortData.filter((x, idx) => {
        return idx >= (this.currentPage - 1) * this.PageSize && idx < this.currentPage * this.PageSize
      })
    },
    showHeader() {
      const columns = (this.chartoption.orginResult && this.chartoption.orginResult.columns) || []
      let group =
        columns.filter(x => {
          return x.usage == 'Group'
        }) || []
      if (group.length == 0 && this.opts && this.styleSet.enablePivot == 'full') {
        return true
      } else {
        return false
      }
    },
    getMaxHeight() {
      const height = document.documentElement.clientHeight
      this.maxHeight = this.rowheight
        ? this.opts && this.styleSet.enablePaging
          ? parseInt(this.rowheight) - 30
          : parseInt(this.rowheight)
        : this.opts && this.styleSet.enablePaging
        ? height - 130
        : height - 100
      this.maxHeight = this.maxHeight > 0 ? this.maxHeight : 1
    },
    appendTable() {
      this.currentPage++
      this.showdata = this.sortData.filter((x, idx) => {
        return idx < this.currentPage * this.PageSize
      })
    },

    redirection(obj) {
      if (this.dontclickable) return
      this.$commonJs2.toDetail(
        this,
        obj.item,
        obj.k,
        obj.col,
        obj.groupskey,
        obj.opts,
        this.chartoption,
        this.chartoption.orginResult,
        obj.groups,
        this.chartDataItem
      )
    },
  },
  mounted() {
    let _this = this
    setTimeout(() => {
      if (_this.$refs.tableref) {
        _this.$refs.tableref.doLayout()
      }
    }, 100)
    window.onresize = function() {
      _this.getMaxHeight()
    }
  },
}
</script>
<style lang="scss">
.my-table {
  // height: 100% !important;
  &.paging-table {
    .el-table__body {
      tbody {
        tr:last-of-type {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
  &.noheader {
    .el-table__header-wrapper {
      display: none;
    }
  }
  .loadbtn {
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    color: #999;
  }

  .el-table__header-wrapper {
    .el-table__header {
      tr {
        th {
          font-weight: normal;
          .cell {
            padding-left: 8px;
            padding-right: 8px;
            min-width: 50px;
          }
        }
      }
    }
  }
}
.my-table,
.funnel-table {
  .el-table {
    // max-height: 100% !important;
  }
  .tabListPage {
    margin-top: 8px;
    overflow: hidden;
    .el-pagination {
      padding-bottom: 0;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0;
    line-height: 32px;
  }
}
.my-table >>> th,
.my-table >>> td {
  white-space: nowrap;
}
.my-table >>> .el-table .cell {
  white-space: nowrap;
}

.my-table .el-table td {
  padding: 0 !important;
  @include theme_bg1($theme-light);
  &.link {
    a {
      color: #70b4fa;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.link_write {
    a {
      color: #fff;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .cell {
    padding: 0 !important;
    min-width: 100% !important;
    > a,
    > div,
    > span {
      display: block;
      padding: 7px 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
