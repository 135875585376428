<template>
  <el-table-column
    :prop="col.prop"
    :resizable="false"
    :label="col.label"
    :align="col.isGroup ? 'left' : 'right'"
    :sortable="sortable"
    :show-overflow-tooltip="true"
    :min-width="colwidth(col.label, col.prop)"
  >
    <template v-if="col.children">
      <my-column
        v-for="(item, index) in col.children"
        :key="index"
        :col="item"
        :opts="opts"
        :newopt="newopt"
        :analysisType="analysisType"
        @redirection="redirection"
      ></my-column>
    </template>
    <template v-slot="props" v-if="!col.children">
      <a
        herf="javascript:void(0)"
        v-if="isUserFun(props.row, col) && !isMobile"
        @click="onRedirection(props.row, col.prop, col)"
        :class="{ link: !dontclickable }"
        :style="bg_val(props.row, col.prop)"
        target="_blank"
        >{{ td_val(props.row[col.prop], col.prop, col, props) }}</a
      >
      <span v-else :class="rate_mark(props.row, col.prop)" :style="bg_val(props.row, col.prop)">{{ td_val(props.row[col.prop], col.prop, col, props) }}</span>
    </template>
  </el-table-column>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MyColumn',
  data() {
    return {
      isPlus: false,
      isMinus: false,
      groupskey: [],

      isMobile: false,

      datacolumns: [],
    }
  },
  props: {
    newopt: {
      type: Object,
    },
    opts: {
      type: Object,
    },
    col: {
      type: Object,
    },
    analysisType: {
      type: String,
    },
    dontclickable: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      basicInfo: state => state.basicInfo,
    }),
    sortable() {
      if (this.opts && this.opts.enablePivot === 'closed' && this.opts.enableConflation === false) {
        return 'custom'
      }
      return false
    },
  },
  created() {
    const ua = navigator.userAgent
    const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      isAndroid = ua.match(/(Android)\s+([\d.]+)/)
    this.isMobile = isIphone || isAndroid

    this.datacolumns = this.newopt.columns || []
    this.groups = this.newopt.groups
    // 获取分组keys集合
    let groupskey = []
    this.datacolumns.forEach(item => {
      if (item.usage === 'Group') {
        let group = this.groups.find(x => x.code == item.code)
        if (group) {
          group.alias = item.alias
        }
        groupskey.push(item.alias)
      }
    })
    this.groupskey = groupskey
  },
  methods: {
    bg_val(item, k) {
      if (k === undefined) {
        return ''
      }
      // let duration = k.match(/\d+/)
      if (this.newopt.version == '2.0') {
        if (['Retention', 'Scatter'].includes(this.analysisType)) {
          let duration = k.split('_').pop()
          if (duration != null) {
            let opacity = parseInt(item[`${duration}_rate`]) / 100
            let style = { background: `rgba(22, 118, 205,${opacity})` }
            if (opacity > 0.35) {
              style.color = '#fff'
            }
            return style
          }
        }
      } else {
        if (['retention', 'scatter'].includes(this.analysisType)) {
          let duration = k.split('_').pop()
          if (duration != null) {
            let opacity = parseInt(item[`rate_${duration}`]) / 100
            let style = { background: `rgba(22, 118, 205,${opacity})` }
            if (opacity > 0.35) {
              style.color = '#fff'
            }
            return style
          }
        }
      }
    },
    td_val(v, prop, col, props) {
      let displayType = ''
      let enablePivot = this.opts && this.opts.enablePivot
      let propset_format = ''
      const setprops = this.newopt.chartSetting?.propSet?.props ?? []
      if (enablePivot == 'closed') {
        displayType = col ? col.displayType : ''
        propset_format = setprops.find(x => x.alias == col.prop || col.prop.includes(x.alias + '_'))
      }
      if (enablePivot == 'part' && col) {
        let alias = col.prop.split('[&]').pop()
        let c = this.datacolumns.find(x => {
          return x.alias == alias
        })
        displayType = c && c.displayType ? c.displayType : ''
        propset_format = setprops.find(x => x.alias == alias || alias.includes(x.alias + '_'))
      }
      if (enablePivot == 'full' && props && props.row) {
        let alias = props.row.prop
        let c = this.datacolumns.find(x => {
          return x.alias == alias
        })
        displayType = c && c.displayType ? c.displayType : ''
        propset_format = setprops.find(x => x.alias == alias || (alias && alias.includes(x.alias + '_')))
      }
      const formatobj = propset_format?.format
      if (displayType || formatobj) {
        v = this.$commonJs.formatDisplay2(v, displayType, formatobj)
      }
      return v
    },
    colwidth(v, prop) {
      if (typeof prop === 'string' && prop.match(/day_dim|event_time_dim/)) {
        return 170
      } else {
        let $span = document.createElement('span')
        document.body.appendChild($span)
        $span.innerText = v
        let w = $span.offsetWidth + 40
        document.body.removeChild($span)
        return w > 120 ? w : 120
      }
    },
    // 判断是否是用户统计 增加外链
    isUserFun(item, k) {
      if (item.other_alias) return false
      if (item.sum || !item[k.prop] || item[k.prop] === '-') {
        return false
      }
      if (this.newopt.version == '2.0') {
        let pivot = this.opts.enablePivot
        if (pivot === 'full' && k.prop.match(/prop_\d+$/) && item.clickableType) {
          return true
        }
        if (k.clickableType) {
          return true
        }
      } else {
        // person_number_  留存 index_count 属性分析 user_number模板
        let other_reg = /(^distinct_totalcount_\d+$)|(^distinct_totalcount_\d+\w+$)|(^person_number_|^index_count|supportcount|user_number|user_count)/
        let user_reg = /(user_fixed_user_mid_distinct$)|(user_fixed_user_mid_distinct_last$)|(user_fixed_user_mid_distinct_circle$)|(user_fixed_user_mid_distinct_contrast$)/
        let shop_reg = /(shop_fixed_shop_mid_distinct$)|(shop_fixed_shop_mid_distinct_last$)|(shop_fixed_shop_mid_distinct_circle$)|(shop_fixed_shop_mid_distinct_contrast$)/
        let pivot = this.opts && this.opts.enablePivot
        if (pivot === 'full' && k.prop.match(/prop_\d+$/) && item.prop && (item.prop.match(user_reg) || item.prop.match(shop_reg))) {
          return true
        }
        if (k.prop.match(user_reg) || k.prop.match(shop_reg) || k.prop.match(other_reg)) {
          return true
        }
        return false
      }
    },
    onRedirection(item, k, col) {
      if (!this.$commonJs._point('DS_F0004')) {
        this.$alert(`权限不够, 不支持查看明细操作`, '提示', {
          confirmButtonText: '我知道了',
        })
        return false
      }
      const dt = {
        item: item,
        k: k,
        col: col,
        groupskey: this.groupskey,
        opts: this.opts,
        groups: this.groups,
      }
      this.$emit('redirection', dt)
    },
    redirection(obj) {
      this.$emit('redirection', obj)
    },
    // 标记对比率升降颜色
    rate_mark(item, k) {
      if (k === undefined) {
        return ''
      }
      let v = item[k]
      let pivot = this.opts && this.opts.enablePivot
      let isRate = false
      let isPlus = false
      let isMinus = false
      // 完全分组 参考 prop {prop:"25001_event_fixed_event_id_count_last"}
      if (pivot === 'full' && item.prop && item.prop.includes('Rate')) {
        isRate = true
      }
      // 非完全分组 参考 key {25001_user_fixed_user_mid_distinct_lastRate:"1972300.00%"}
      if (pivot !== 'full' && k.includes('Rate')) {
        isRate = true
      }
      if (isRate) {
        if (parseFloat(v) > 0) {
          isPlus = true
        } else if (parseFloat(v) < 0) {
          isMinus = true
        }
      }
      let contrastTrendColor = this.basicInfo.project.contrastTrendColor || 0
      return { 'arrow-up': isPlus, 'arrow-down': isMinus, red_green: contrastTrendColor == 1 }
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  // @include high_color1($highcolor-cheng);
  color: #70b4fa;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
